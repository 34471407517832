import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import './home-logos.css'
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const TrustedClientLogos = () => {
    const [sliderRef] = useKeenSlider<HTMLDivElement>({
        centered: true,
        breakpoints: {
            "(min-width: 640px)": {
                slidesPerView: 3,
            },
            "(min-width: 940px)": {
                slidesPerView: 5,
            },
        },
        slidesPerView: 1.5,
        loop: true,
        spacing: 80,
        mode: "free-snap",
        created: (slider: { moveToSlide: (arg0: number) => void; }) => {
            slider.moveToSlide(5)
        },
        slideChanged: (slider) => {
            slider.moveToSlide(slider.details().absoluteSlide + 5, 70000)
        }
    });

    return (
        <section className="home-logos-wrapper">
            <p className="home-logos-description teal ">Trusted by</p>
            <div className="keen-slider logos-container " ref={sliderRef}>
                <div className="keen-slider__slide   d-grid place-content-center home-logo-item-container-cisco">
                    <StaticImage
                        alt="cisco logo"
                        src="../../assets/utkarsh/logo-cisco.png"
                        className="home-logo-item"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"
                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="hexagon logo"
                        src="../../assets/utkarsh/logo-hexagon.png"
                        className="home-logo-item"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        height={120}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="everbridge logo"
                        src="../../assets/utkarsh/logo-everbridge.png"
                        className="home-logo-item"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div>
                {/* <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="cloudbees logo"
                        src="../../assets/utkarsh/logo-cloudbees.png"
                        className="home-logo-item"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div> */}
                {/* <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="xmatters logo"
                        src="../../assets/utkarsh/logo-xmatters.png"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div> */}
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="venafi logo"
                        src="../../assets/utkarsh/venafi-logo.png"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="catchpoint logo"
                        src="../../assets/utkarsh/catchpoint-logo-large.png"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div>
                {/* <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="mholland logo"
                        src="../../assets/utkarsh/logo-mholland.png"
                        loading="eager"
                        layout="constrained"
                        height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div> */}
                {/* <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="mile iq logo"
                        src="../../assets/utkarsh/mile-iq.png"
                        loading="eager"
                        layout="constrained"
                        height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                </div> */}
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="cority logo"
                        src="../../assets/utkarsh/cority.png"
                        loading="eager"
                        layout="constrained"
                        height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"
                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="motive logo"
                        src="../../assets/utkarsh/motivelogo.png"
                        loading="eager"
                        layout="constrained"
                        height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"
                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="automox logo"
                        src="../../assets/utkarsh/automox.png"
                        loading="eager"
                        layout="constrained"
                        height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"
                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="Trintech logo"
                        src="../../assets/utkarsh/Trintech.png"
                        loading="eager"
                        layout="constrained"
                        height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"
                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container" style={{height: '70px'}}>
                    <StaticImage
                        alt="sumo-logic logo"
                        src="../../assets/utkarsh/sumo-logic.png"
                        loading="eager"
                        layout="constrained"
                        // height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"
                    />
                </div>
                <div className="keen-slider__slide  d-grid place-content-center home-logo-item-container">
                    <StaticImage
                        alt="Mimecast logo"
                        src="../../assets/utkarsh/Mimecast.png"
                        loading="eager"
                        layout="constrained"
                        height={120}
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"
                    />
                </div>
            </div>
        </section>
    )
}

export default TrustedClientLogos